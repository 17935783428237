/* Popular Courses Section Styles */

.courses-section {
  background: linear-gradient(to bottom, #ffffff, #f8faff, #ffffff);
  position: relative;
  overflow: hidden;
}

/* Background pattern */
.courses-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b82f6' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.4;
  z-index: 0;
}

/* Section header */
.courses-section .section-header {
  position: relative;
  z-index: 1;
}

/* Underline accent */
.courses-section .accent-underline {
  height: 4px;
  width: 80px;
  background: linear-gradient(to right, #ff6b00, #ff9d00);
  border-radius: 2px;
  margin-top: 0.5rem;
}

/* Course card container */
.course-card-container {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

/* Course card */
.course-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 35px -10px rgba(0, 0, 0, 0.2);
}

/* Course image */
.course-image-container {
  position: relative;
  overflow: hidden;
  height: 180px;
}

.course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.course-card:hover .course-image {
  transform: scale(1.05);
}

/* Premium badge */
.premium-badge {
  position: absolute;
  top: 12px;
  left: 12px;
  background: linear-gradient(to right, #ffd700, #ffb700);
  color: #333;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0.35rem 0.75rem;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Course content */
.course-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.course-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.4;
  min-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Course features */
.course-features {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #3b82f6;
}

.feature-text {
  font-size: 0.875rem;
  color: #4b5563;
}

/* Course action */
.course-action {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.syllabus-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: linear-gradient(to right, #3b82f6, #60a5fa);
  color: white;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  width: 100%;
}

.syllabus-button:hover {
  background: linear-gradient(to right, #2563eb, #3b82f6);
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.3);
}

.syllabus-icon {
  transition: transform 0.3s ease;
}

.syllabus-button:hover .syllabus-icon {
  transform: translateY(2px);
}

/* View all button */
.view-all-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: white;
  color: #3b82f6;
  border: 2px solid #3b82f6;
  font-weight: 600;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.view-all-button:hover {
  background: #3b82f6;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.3);
}

.view-all-icon {
  transition: transform 0.3s ease;
}

.view-all-button:hover .view-all-icon {
  transform: translateX(4px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .course-card-container {
    padding: 1rem;
  }
  
  .course-image-container {
    height: 160px;
  }
  
  .course-content {
    padding: 1rem;
  }
  
  .course-title {
    font-size: 1rem;
  }
} 