/* Lead Form Section Styles */

.lead-form-section {
  position: relative;
  overflow: hidden;
}

/* Circuit pattern overlay */
.circuit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.2;
  z-index: 1;
}

/* Glowing dots */
.glowing-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.glowing-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.4);
  animation: pulse 3s infinite;
}

.glowing-dot:nth-child(1) {
  top: 15%;
  left: 10%;
  animation-delay: 0s;
}

.glowing-dot:nth-child(2) {
  top: 25%;
  left: 20%;
  animation-delay: 0.5s;
}

.glowing-dot:nth-child(3) {
  top: 60%;
  left: 5%;
  animation-delay: 1s;
}

.glowing-dot:nth-child(4) {
  top: 75%;
  left: 25%;
  animation-delay: 1.5s;
}

.glowing-dot:nth-child(5) {
  top: 20%;
  left: 80%;
  animation-delay: 2s;
}

.glowing-dot:nth-child(6) {
  top: 40%;
  left: 90%;
  animation-delay: 2.5s;
}

.glowing-dot:nth-child(7) {
  top: 70%;
  left: 85%;
  animation-delay: 1.2s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}

/* Content container */
.lead-content {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 16px;
  border-left: 4px solid #FFD700;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
}

/* Heading styles */
.lead-heading {
  position: relative;
  margin-bottom: 2rem;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
  line-height: 1.2;
}

.lead-heading .highlight {
  color: #FFD700;
  position: relative;
  display: inline-block;
}

.lead-heading .highlight::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(255, 215, 0, 0.3);
  z-index: -1;
  border-radius: 4px;
}

.lead-heading::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #FFD700, transparent);
  border-radius: 2px;
}

.lead-heading-center::after {
  left: 50%;
  transform: translateX(-50%);
}

/* Description text */
.lead-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Feature items list */
.feature-items-list {
  margin-top: 2rem;
}

.feature-item-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2.5rem;
}

.feature-item-icon {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 215, 0, 0.15);
  border-radius: 50%;
  color: #FFD700;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.2);
}

.feature-item-icon .icon {
  font-size: 1.2rem;
}

.feature-item-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.05rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Form container */
.lead-form-container {
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.lead-form-container::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05),
    rgba(255, 215, 0, 0.2)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.lead-form-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

/* Form inputs */
.form-input {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
}

.form-input:focus {
  outline: none;
  border-color: #FFD700;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.2);
}

.form-input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Submit button */
.submit-button {
  background: #FFD700;
  color: #000;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  padding: 0.75rem 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  box-shadow: 0 4px 10px rgba(255, 215, 0, 0.3);
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(255, 215, 0, 0.4);
  background: #FFCC00;
}

.submit-button:active {
  transform: translateY(0);
}

.submit-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: left 0.7s ease;
}

.submit-button:hover::before {
  left: 100%;
}

.submit-button-icon {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.submit-button:hover .submit-button-icon {
  transform: translateX(3px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .lead-heading {
    font-size: 2rem;
    text-align: center;
  }
  
  .lead-heading::after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .lead-content {
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
  
  .feature-items-list {
    margin-top: 1.5rem;
  }
  
  .lead-form-container {
    padding: 1.5rem;
  }
} 