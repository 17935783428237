.swiper {
  width: 100%;
  padding: 20px 60px 60px 60px;
  min-height: 520px;
}

.swiper-slide {
  height: auto;
  display: flex;
}

.swiper-button-next,
.swiper-button-prev {
  color: #3b82f6 !important;
  background: rgba(255, 255, 255, 0.95);
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
  transition: all 0.3s ease;
  transform: translateY(-50%);
  top: 45%;
}

.swiper-button-next {
  right: 15px;
}

.swiper-button-prev {
  left: 15px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 22px !important;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #ff6b00 !important;
  background: #ffffff;
  box-shadow: 0 6px 16px rgba(255, 107, 0, 0.25);
  transform: translateY(-50%) scale(1.1);
}

.swiper-button-next:hover:after {
  transform: translateX(2px);
}

.swiper-button-prev:hover:after {
  transform: translateX(-2px);
}

.swiper-pagination {
  bottom: 20px !important;
}

.swiper-pagination-bullet {
  background: #3b82f6 !important;
  opacity: 0.5;
  width: 10px;
  height: 10px;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ff6b00 !important;
  width: 24px;
  border-radius: 4px;
}

/* Card effect styles */
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  background-image: none !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .swiper {
    padding: 20px 20px 60px 20px;
    min-height: 480px;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .swiper {
    padding: 20px 10px 60px 10px;
    min-height: 450px;
  }
} 