/* Hero Section Styles */

.hero-section {
  position: relative;
  overflow: hidden;
}

/* Background enhancements */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.1;
  z-index: 0;
}

/* Particles animation */
.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.particles-container::before,
.particles-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 25% 25%, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    radial-gradient(circle at 75% 75%, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  animation: particleAnimation 20s linear infinite;
  opacity: 0.3;
}

.particles-container::after {
  background-size: 30px 30px;
  animation-duration: 30s;
  animation-direction: reverse;
}

@keyframes particleAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 100px;
  }
}

/* Typewriter container */
.typewriter-container {
  margin-bottom: 1.5rem;
  position: relative;
}

.typewriter-container::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #1DB954, transparent);
  border-radius: 3px;
}

/* Student count highlight */
.student-count {
  position: relative;
  display: inline-block;
  padding: 0 4px;
}

.student-count::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: rgba(255, 217, 102, 0.3);
  z-index: -1;
  border-radius: 3px;
}

/* Form container */
.form-container {
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.form-container::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05),
    rgba(29, 185, 84, 0.2)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/* Form inputs */
.form-select,
.form-input {
  transition: all 0.3s ease;
}

.form-select:hover,
.form-input:hover {
  border-color: rgba(255, 255, 255, 0.3);
}

/* Explore button */
.explore-button {
  position: relative;
  overflow: hidden;
}

.explore-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: left 0.7s ease;
}

.explore-button:hover::before {
  left: 100%;
}

/* Submit button */
.submit-button {
  position: relative;
  overflow: hidden;
}

.submit-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: left 0.7s ease;
}

.submit-button:hover::before {
  left: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section {
    height: auto !important;
    min-height: 800px;
  }
  
  .form-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 640px) {
  .typewriter-container {
    transform: scale(0.9);
    transform-origin: left;
  }
} 